/* eslint-disable max-lines */
import { defineComponent } from 'vue';
import useProducts from '@/compositions/products';
import useTakeAway from '@/compositions/takeaway';
import useReservations from '@/compositions/reservations';
export default defineComponent({
    name: 'TakeAwayTotalsTable',
    components: {},
    props: {},
    setup() {
        return {
            ...useProducts(),
            ...useReservations(),
            ...useTakeAway()
        };
    },
    data: () => ({
        showKitchenName: false
    }),
    computed: {
        productCount() {
            return this.$store.direct.getters.takeaway.productCount;
        }
    }
});
