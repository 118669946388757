import { defineComponent } from 'vue';
import TakeAwayOverviewTable from '@/components/TakeAway/TakeAwayOverviewTable';
import TakeAwayTotalsTable from '@/components/TakeAway/TakeAwayTotalsTable';
import TakeAwayFilter from '@/components/TakeAway/TakeAwayFilter';
import useTakeAway from '@/compositions/takeaway';
export default defineComponent({
    name: 'TakeAwayOverview',
    components: {
        TakeAwayOverviewTable,
        TakeAwayFilter,
        TakeAwayTotalsTable
    },
    setup() {
        return { ...useTakeAway(true) };
    },
    computed: {
        isSuperUser() {
            return this.$store.direct.getters.auth.isSuperUser;
        }
    }
});
