import { defineComponent } from 'vue';
import Datepicker from 'vue3-datepicker';
import { nl } from 'date-fns/locale';
import { cloneDeep } from 'lodash';
import useTakeAway from '@/compositions/takeaway';
import CustomerSearchField from '@/components/Customers/CustomerSearchField';
export default defineComponent({
    name: 'ReservationFilter',
    components: {
        Datepicker,
        CustomerSearchField
    },
    setup() {
        return { ...useTakeAway(true) };
    },
    data: () => ({
        localFilter: {
            field: null,
            value: '',
            fromDate: null,
            toDate: null
        },
        locale: nl,
        customerId: ''
    }),
    computed: {
        takeawayFilter() {
            return this.$store.direct.state.takeaway.filter;
        }
    },
    watch: {
        takeawayFilter: {
            handler(filter) {
                this.localFilter = cloneDeep(filter);
            },
            immediate: true
        }
    },
    methods: {
        updateFilter() {
            if (this.customerId !== '') {
                this.localFilter.field = 'uid';
                this.localFilter.value = this.customerId;
            }
            else {
                this.localFilter.field = null;
                this.localFilter.value = '';
            }
            this.$store.direct.dispatch.takeaway.applyFilter(this.localFilter);
        }
    }
});
